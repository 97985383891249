@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.experience-container {
  font-family: 'Poppins', sans-serif;
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.experience-item {
  cursor: pointer;
  margin-bottom: 10px;
  border-left: 3px solid #007bff;
  padding: 10px 20px;
  overflow: hidden; /* Ensure that the content is clipped */
  transition: all 0.3s ease;
}

.experience-item.open {
  background-color: #f0f8ff;
}

.experience-item h3 {
  margin: 0;
  color: #007bff;
  transition: color 0.3s ease;
}

.experience-item:hover h3 {
  color: #f19524e0;
}

.period {
  font-style: italic;
  color: #555;
}

.duties {
  max-height: 0; /* Start with content hidden */
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  padding-left: 20px;
  margin-top: 10px;
}

.duties li {
  margin-bottom: 5px;
}

/* Mobile-specific styling */
@media (max-width: 768px) {
  .experience-item {
    margin-bottom: 20px;
    border-left: 0;
    border-bottom: 1px solid #007bff;
  }

  .experience-item:hover h3 {
    color: #007bff; /* Remove hover effect */
  }
}
