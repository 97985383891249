.hobbies-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  justify-content: center; /* Center the cards in the container */
  padding: 20px;
  margin-bottom: 80px;
  /*border: solid red 1px;*/
}

/* Hobby Card */
.hobby-card {
  width: 150px;
  height: 150px;
  /*border: solid red 1px;*/
  perspective: 1000px; 
}

/* Inner Card for 3D Flip */
.hobby-card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.hobby-card:hover .hobby-card-inner { 
  transform: rotateY(180deg); /* Default desktop flipping effect */
}

/* Front and Back of Card */
.hobby-card-front,
.hobby-card-back {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  position: absolute;
  border-radius: 10px;
  font-size: 1.2em;
  color: #fff;
}


.hobby-card-front {
  background-color: #007bff;
}

.hobby-card-back {
  background-color: #f19524e0;
  transform: rotateY(180deg);
}

.resetMP {
  margin: 0;
  padding: 0;
}
