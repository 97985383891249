.skills-container {
  max-width: 850px;
  margin: auto;
  padding: 30px 20px;
}

.skills-heading {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 40px;
  color: #333;
}

.skills-sort-select {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.skills-sort-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 150px;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  border: 2px solid #007bff;
  border-radius: 20px;
  background-color: #fff;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}

.skills-sort-select select:focus {
  border-color: #f19524;
}

.skills-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.skill-item {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align content to the left */
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 15px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.skill-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.skill-name {
  font-weight: 500;
  font-size: clamp(0.9rem, 1vw, 1.1rem);
  color: #007bff;
  transition: color 0.3s ease;
  white-space: normal;
  word-wrap: break-word;
  flex: 1;
  margin-right: 10px;
  overflow-wrap: break-word;
  text-align: left; /* Align text to the left */
}

.skill-name:hover {
  color: #f19524;
}

.skill-circle {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  margin-left: 15px;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .skills-list {
    grid-template-columns: 1fr;
  }

  .skill-item {
    justify-content: flex-start;
  }
}
