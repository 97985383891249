body {
  background: linear-gradient(120deg, #f6f9fc, #e9eff5);
  animation: GradientShift 15s ease infinite;
  color: #333;
  font-family: 'Arial', sans-serif;
  transition: background 0.3s linear;
}

@keyframes GradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

h2, h3, p {
  margin-left: 20px;
}

.Experience, .Skills, .Hobbies, .AboutMe {
  padding: 20px;
  margin: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
  transition: background-color 0.3s;
}

.Experience:hover, .Skills:hover, .Hobbies:hover  , .AboutMe:hover {
  background-color: rgba(245, 245, 245, 0.9);
}
