/* Contact.css */
.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    min-height: 100vh;
    color: #fff;
    font-family: 'Poppins', sans-serif;
  }
  
  .contact-header {
    margin-bottom: 40px;
  }
  
  /* Contact.css */
.contact-header h1 {
    font-size: 2.5rem;
    color: #fff; /* Solid color without gradient */
    animation: pulse 2s infinite ease-in-out;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  
  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-link {
    display: flex;
    align-items: center;
    margin: 10px 0;
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    transition: transform 0.2s ease-in-out;
  }
  
  .contact-link i {
    margin-right: 10px;
  }
  
  .contact-link:hover {
    transform: scale(1.1);
    text-decoration: underline;
  }
  