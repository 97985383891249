/* Main container styling */
.about-me-item {
    cursor: pointer;
    margin-bottom: 10px;
    padding: 10px 20px;
    overflow: hidden;
    transition: all 0.4s ease;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto; /* Center the container */
    display: block;
}

.about-me-item.open {
    background-color: rgba(240, 248, 255, 1);
}

/* Hover effect */
.about-me-item:hover {
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
}

/* Fancy heading */
.about-me-heading {
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 10px;
}

/* Name and profession styling */
.about-me-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.about-me-name {
    font-size: 1.6rem;
    font-weight: 700;
    color: #f19524;
}

.about-me-profession {
    font-size: 1.4rem;
    font-weight: 600;
    color: #007bff;
}

/* Education section */
.about-me-education {
    margin-top: 10px;
    text-align: left;
}

.education-heading {
    font-size: 1.3rem;
    color: #007bff;
    text-decoration: underline;
    margin-bottom: 10px;
}

/* Grid layout for education items */
.education-item {
    display: grid;
    grid-template-columns: 1fr auto;
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 10px;
}

.institution {
    font-weight: 500;
}

.degree {
    font-size: 0.9rem;
    color: #777;
    margin-top: 5px;
}

.education-period {
    color: #007bff;
    align-self: center;
    text-align: right;
}

/* Expand/collapse icon */
.expand-icon {
    display: block;
    margin: 15px auto;
    font-size: 2rem;
    color: #007bff;
    transition: color 0.3s ease, transform 0.3s ease;
}

.expand-icon:hover {
    color: #f19524;
    transform: scale(1.2);
}

/* Expandable content section */
.about-me-expanded {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    margin-top: 10px;
}

.journey-text {
    font-size: 1rem;
    color: #333;
    margin-top: 10px;
    text-align: Left;
}

/* Animations */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(15px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Media Queries for Mobile */
/* Media Queries for Mobile */
@media (max-width: 768px) {
    /* Center elements for mobile view */
    .about-me-heading {
        text-align: center;
        margin-bottom: 20px;
        font-size: 2rem; /* Larger and more impactful heading */
    }

    .about-me-header {
        display: block;
        text-align: center;
    }

    .about-me-name {
        font-size: 1.8rem; /* Slightly larger name font */
        font-weight: 700; /* Make the name bolder */
        color: #f19524; /* Keep the orange accent */
        margin-bottom: 10px;
    }

    .about-me-profession {
        font-size: 1.4rem;
        font-weight: 600;
        color: #333;
        margin-bottom: 20px;
    }

    /* Adjust education section for mobile */
    .about-me-education {
        text-align: center;
    }

    .education-heading {
        font-size: 1.4rem;
        color: #007bff;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        margin-bottom: 20px;
    }

    /* Stack education items vertically on mobile */
    .education-item {
        display: block;
        margin-bottom: 20px;
        padding: 10px 15px;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.95); /* Add slight transparency */
        border-radius: 12px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .education-item:hover {
        transform: translateY(-5px); /* Slight lift on hover */
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Enhance shadow on hover */
    }

    /* Institution styling */
    .institution {
        font-size: 1.2rem;
        font-weight: 600;
        color: #333;
        margin-bottom: 5px;
        text-align: center;
        display: block; /* Each item takes its own line */
    }

    /* Degree styling */
    .degree {
        font-size: 1rem;
        color: #555;
        margin-bottom: 5px;
        font-style: italic;
        text-align: center;
        display: block; /* Each item takes its own line */
    }

    /* Education period styling */
    .education-period {
        font-size: 1rem;
        color: #007bff;
        margin-bottom: 10px;
        font-weight: bold;
        text-align: center;
        display: block; /* Each item takes its own line */
    }

    /* Expandable section icon */
    .expand-icon {
        font-size: 1.8rem;
        color: #f19524;
        margin-top: 10px;
        transition: color 0.3s ease, transform 0.3s ease;
    }

    .expand-icon:hover {
        color: #007bff;
        transform: scale(1.2);
    }

    /* Adjust journey text for mobile */
    .journey-text {
        padding: 10px;
        text-align: center;
        font-size: 1rem;
        color: #555;
    }

    /* Adjust container padding and margins */
    .about-me-item {
        padding: 20px;
        margin-bottom: 20px;
        background-color: rgba(255, 255, 255, 0.95);
        border-radius: 10px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .about-me-item:hover {
        transform: translateY(-5px); /* Lift effect on hover */
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
    }
    ul{
        padding:0px;
    }
}




