.App {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f3f4f6; /*#f3f4f6*/
  min-height: 100vh;
}


.container {
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h1 {
  color: #333;
}

.section {
  margin-bottom: 30px;
}

.section h2 {
  color: #333;
}

.section p {
  color: #666;
}

.footer {
  text-align: center;
  margin-top: 50px;
  color: #666;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* General scrollbar styling for WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
  border-radius: 10px; /* Optional: rounded corners for the track */
}

/* Thumb (the scrollable part) */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar */
  border-radius: 10px; /* Optional: rounded corners for the thumb */
}

/* Hover state for the thumb */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color when hovering */
}

/* For Firefox, reduce scrollbar width and change colors */
body {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: #888 #f1f1f1; /* Thumb color followed by track color */
}
