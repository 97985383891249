/* Projects.css */
.projects-container {
    padding: 20px;
  }
  
  .projects-title {
    text-align: center;
  }
  
  .projects-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .project-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
  }

  p{
    margin: 1.2em;
  }

  h3{
    margin: 1.2em;
  }

  .project-image {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 10px; /* Space between image and description */
  }
  
  /* Responsive images */
  .project-images-container {
    display: flex;
    gap: 10px; /* Spacing between images */
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .project-image img {
    max-width: 150px; /* Adjust based on your preference */
    height: auto;
    border-radius: 4px;
  }
  