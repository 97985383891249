/* Base Styles for App Bar */
.app-bar {
  background-color: #333;
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between; /* Logo stays on the left and hamburger/menu on the right */
}

/* Logo Styles */
.logo {
  margin-left: 0; /* Ensure the logo stays on the left edge */
  z-index: 2; /* Ensure the logo is on top */
}

.logo a {
  color: #fff;
  font-weight: bold;
  font-size: 1.8em;
  font-family: 'Poppins', sans-serif;
  background: -webkit-linear-gradient(45deg, #007bff, #ff55a5, #ffab00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.logo a:hover {
  transform: scale(1.05);
  text-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3);
}

/* Centering the Navigation Links */
.nav-links {
  list-style-type: none;
  position: absolute; /* Use absolute positioning */
  left: 50%; /* Start at the center of the screen */
  transform: translateX(-50%); /* Shift it left by half its width to center it */
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
}

.nav-links li a:hover {
  background-color: #555;
}

/* Hamburger Menu */
.hamburger {
  display: none;
}

/* Media Query for Screens Smaller than 768px (Mobile) */
@media (max-width: 768px) {
  /* Show Hamburger Menu */
  .hamburger {
    display: block;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    right: 10px; /* Ensure hamburger stays on the right */
    top: 50%;
    transform: translateY(-50%);
  }

  /* Hide the navigation links by default */
  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: #333;
    width: calc(100% - 20px); /* Adjust width for spacing */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding-top: 7px;
    padding-bottom: 7px;
    opacity: 0; /* Hidden initially */
    max-height: 0; /* Collapse the menu initially */
    overflow: hidden; /* Prevent overflow during animation */
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  /* Show the nav links when the hamburger menu is clicked */
  .nav-links.open {
    max-height: 300px; /* Set to the max height when the menu is opened */
    opacity: 1; /* Show the menu */
  }

  .nav-links li {
    margin: 3px 7px;
    padding: 10px;
    background-color: #505050;
    box-shadow: 1px 1px 2px #777777;
    max-width: 100%;
    border-radius: 5px;
  }

   /* Make the entire li clickable by expanding the a tag */
   .nav-links li a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
  }

  /* Ensure the logo stays on the left edge */
  .logo {
    position: relative;
    left: 0;
    margin-left: 10px; /* Slight margin to give a little space */
  }
}
